html {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}
#root {
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.main {
    height: 100%;
    width: 100%;
    overflow: hidden;
    background: #fff;
}
.MuiButton-containedPrimary {
    background-color: #0395fe !important;
}
.MuiButton-containedPrimary:hover, .MuiButton-containedPrimary:active {
    background-color: #61baf9 !important;
}
.MuiButton-outlinedPrimary {
    color: #0395fe !important;
    border: 1px solid rgba(0, 96, 100, 0.5) !important;
}
.MuiButton-outlinedPrimary:hover,  .MuiButton-outlinedPrimary:active{
    color: #0395fe !important;
    border: 1px solid rgba(0, 96, 100, 0.5) !important;
}
.MuiDialog-paperFullScreen {
    background: #fff;
}
.MuiTabs-flexContainer {
    button {
        font-size: 0.7em;
        min-width: 70px;
    }
}
.MuiButton-containedSecondary {
    background-color: #000 !important;
}
.custom-toast.Toastify__toast--success {
    background: #61baf9; 
}
.custom-toast.Toastify__toast--warning {
    color: #000;
    background: #ffc107; 
}
.hidden {
    opacity: 0;
}
@keyframes appear {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.appear {
    animation: 1s appear forwards;
}
h1 {
    margin: 0 0 0.5em;
}
.MuiTableContainer-root.MuiPaper-root {
    background-color: transparent !important;
}
.MuiTableContainer-root.MuiPaper-elevation1 {
    box-shadow: none;
}
.MuiTableContainer-root {
    overflow: auto;
    border: 1px solid;
    border-color: rgba(0, 0, 0, 0.23);
    border-top: none;
    overflow: initial !important;
}
.MuiTableCell-root {
    border: none !important;
}
.MuiTableRow-root {
    border-bottom: 1px solid rgba(0, 0, 0, 0.23);
}
.MuiTableRow-root:last-child {
    border: none;
}
.MuiTableRow-root:first-child {
    border-top: 1px solid rgba(0, 0, 0, 0.23);
}
.entity-dialog-container {
    .MuiFormControl-root {
        margin: 0.5em 0;
    }
}


.okeoke-data-table {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: var(--main-box-color);
    //box-shadow: 3px 3px 15px rgb(0 0 0 / 25%);
    border-radius: 20px;
    color: var(--main-text-color);
    .message {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      text-decoration: underline;
      cursor: pointer;
    }
    .data-table-inner {
      overflow: auto;
      flex-grow: 1;
      padding-right: 15px;
      // height:500px;
  
      // height: 100%;
      // &.paginated {
      //   height: calc(100% - 32px);
      // }
    }
    .data-table {
      width: 100%;
      thead {
        tr {
          th {
            user-select: none;
            &.sortable {
              cursor: pointer;
            }
            &.head-align-right {
              text-align: right;
            }
            &.head-align-left {
              text-align: left;
            }
            &.head-align-center {
              text-align: center;
            }
            .sort-icon {
              height: 14px;
              position: absolute;
              right: 3px;
              svg {
                height: 14px;
              }
            }
          }
        }
      }
      tbody {
        tr {
          &:hover {
            background:#D9D9D9;
          }
          td {
            border-bottom: 1px solid #D9D9D9;
            .image-icon {
              svg {
                .svgText {
                  opacity: 0.4;
                  stroke: var(--main-text-color);
                  fill: transparent;
                }
              }
            }
            .image-icon.selected {
              svg {
                .svgText {
                  opacity: 0.9;
                  stroke: #FF5C5C;
                  fill: transparent;
                }
              }
            }
            svg {
              height: 24px;
              width: 24px;
              cursor: pointer;
              .svgText {
                fill: var(--main-text-color)
              }
            }
            &.checkbox-cell {
              svg {
                .svgText {
                  fill: transparent;
                }
              }
            }
            &.cell-align-right {
              text-align: right;
            }
            &.cell-align-left {
              text-align: left;
            }
            &.cell-align-center {
              text-align: center;
              .clickable {
                justify-content: center;
              }
            }
          }
        }
        .under-row-container {
          &.under-row-container-hidden {
            display: none;
          }
        }
      }
    }
    .data-cell {
      max-width: 200px;
      white-space: break-spaces;
        text-overflow: ellipsis;
      &.tight {
        max-width: 80px;
      }
      .grey {
        svg {
          .svgText {
            fill: var(--grey)
          }
        }
      }
    }
    .data-cell.dnd {
      cursor: grab;
      svg {
        cursor: grab;
      }
    }
    .data-cell.totalPrice {
      min-width: 80px;
    }
    .pagination-container {
      user-select: none;
      width: 100%;
      height: 2em;
      display: flex;
      place-content: center;
      align-items: center;
      .pagination-inner {
        width: 216px;
        font-size: 1.2em;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .pagination-button {
        width: 24px;
        height: 24px;
        display: flex;
        place-content: center;
        cursor: pointer;
        &.selected {
          font-weight: bold;
        }
      }
    }
  }