.entity-list-container {
    width: 100%;
    height: 100%;
    .entity-list-header {
        display: flex;
        justify-content: space-between;
        .entity-type-select {
            height: 3em;
            display: flex;
        }
        .btn {
            cursor: pointer;
            margin: 4px;
        }
    }
    .data-grid-container {
        height: calc(100% - 116px);
    }
}
.add-event-dialog-container {
    height: calc(100% - 3.5em);
    form {
        height: 100%;
        .MuiDialogContent-root {
            height: calc(100% - 4em);
            padding: 0 4em 0 1em;
            .form-container {
                height: 100%;
                display: flex;
                flex-direction: column;
                .MuiFormControl-root {
                    margin-top: 0.5em;
                    margin-bottom: 0.5em;
                }
            }
        }
        .react-datetime-picker {
            padding: 0;
            margin-top: 0.5em;
            margin-bottom: 0.5em;
        }
    }
}
.btn {            
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    border-radius: 4px;
    border: 1px solid rgba(0,0,0,0.3);
    padding: 0 8px;
    margin: 0 0.5em 0.5em 0;
    color: rgba(0,0,0,0.6);
}
.btn.selected {
    color: #fff;
    background-color: rgba(0,0,0,0.6);
}