.entity-dialog-container {
    height: calc(100% - 4em);
    form {
        height: 100%;
        .MuiDialogContent-root {
            height: calc(100% - 5em);
            .datagrid-container {
                height: 100%;
            }
        }
    }
}