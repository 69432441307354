.booking-list-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
    width: 100%;
    height: calc(100% - 48px);
    h1 {
        margin: 0;
    }
    .data-grid-container {
        height: calc(100% - 104px);
    }
}

.entity-type-select {
    height: 36px;
    display: flex;
    align-items: center;
    padding: 0 8px; 
    margin: 1em 0;
    .react-datetime-picker {
        padding: 0;
        margin: 0 8px 0 3px;
    }
    .btn {
        margin: 0 8px;
    }
}

.item-name {
    height: 100%;
    width: 100%;
    line-height: 1;
    word-wrap: break-word;
    white-space: pre-wrap; 
    overflow: hidden;
    display: flex;
    align-items: center;
}
.total-price {
    text-align: right;
    font-weight: bold;
}
.MuiDataGrid-cell {
    button {
        font-size: 0.8em;
    }
}
.dialog-content {
    width: 100%;
    min-height: 400px;
    display: flex;
}
.entity-box-container {
    width: 450px;
    flex-direction: column;
    padding: 0 1em;
    height: 400px;
    overflow: hidden;
    .entity-box-actions {
        height: 3em;
        button {
            margin: 0 0.5em 0.5em 0;
        }
    }
    .entity-box-buttons {
        height: calc(100% - 3em);
        overflow: auto;
        .entity-button {
            margin: 0 0.25em;
            display: inline-flex;
            align-items: center;
            padding: 0.3em 1em;
            cursor: pointer;
            border-radius: 4px;
            border: 1px solid #00bcd4;
            margin-bottom: 0.5em;
            user-select: none;
            overflow: hidden;
            font-size: 0.7em;
            @media (min-width: 950px) {
                font-size: 0.9em;
            }
        }
        .entity-button.selected {
            background-color: #00bcd4;
            color: #fff;
        }
    }
}
.action-container {
    display: flex;
    justify-content: flex-end;
    button {
        margin: 0.5em;
    }
}

