.nav-container {
    background-color: #0395fe;
    height: 36px;
    width: 100%;
    overflow: hidden;
    padding: 0 48px 4px 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .booking-container {
        height: 100%;
        .MuiButton-root {
            margin-top: 3px;
            .MuiButton-label {
                font-size: 10px;
            }
        }
    }
    .MuiTabs-root {
        min-height: unset;
    }
    .MuiTab-root {
        min-height: unset;
    }
    .MuiButton-containedPrimary {
        background-color: #fff !important;
        color: #000 !important;
    }
    .MuiInputBase-root {
        color: #fff !important;
        text-transform: uppercase;
    }
    .MuiInput-underline:before, .MuiInput-underline:after {
        border: none;
    }
    .MuiTabs-flexContainer {
        color: #fff !important;
    }
    .PrivateTabIndicator-colorSecondary-3 {
        background-color: #fff !important;
    }
}