.nav-container-reports {
    background-color: #fff;
    height: 3em;
    width: 100%;
    overflow: hidden;
    padding: 0 8em 0 1em;
    display: flex;
    justify-content: space-between;
    .MuiInput-underline:before, .MuiInput-underline:after {
        border: none;
    }
}
.MuiTab-textColorInherit {
    opacity: 1 !important;
}
.booking-list-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    h1 {
        margin: 0;
    }
    .data-grid-container {
        height: calc(100% - 8.5em);
    }
}

.entity-type-select {
    height: 4em;
    display: flex;
    padding: 0 0px;
    .btn {            
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        border-radius: 4px;
        border: 1px solid rgba(0,0,0,0.3);
        padding: 0 8px;
        margin: 0em 0em 1em 1em;
        color: rgba(0,0,0,0.6);
        cursor: pointer;
    }
    .btn.selected {
        color: #fff;
        background-color: #0395fe;
    }
    .react-datetime-picker {
        padding: 0em 0em 1em 0;
        .react-datetime-picker__wrapper {
            padding: 0 14px;
            border-radius: 4px;
            border-color: rgba(0, 0, 0, 0.25);
        }
    }
}

.MuiDataGrid-cell {
    button {
        font-size: 0.8em;
    }
}

