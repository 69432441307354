.entity-dialog-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.param-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin: 0 0 1em 0;
    border-bottom: 1px solid rgba(0,0,0,0.3);
    padding: 0 0 2em 0;
    div {
        margin: 0.5em;
    }
} 