.MuiDialog-container {
    .form-container {
        flex-grow: 1;
        form {
            height: 100%;
            display: flex;
            flex-direction: column;
            .MuiDialogContent-root {
                .data-grid-container {
                    height: 100%;
                }
            }
        }
    }
}