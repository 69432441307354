.booking-container {
    display: flex;
    justify-content: center;
    align-items: center;
    .MuiButton-root {
        padding: 4px 10px !important;
    }
}
.booking-dialog-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    user-select: none;
    .entity-type-select {
        height: 2em;
        display: flex;
        justify-content: space-between;
        padding: 0.25em;
        .btn {            
            display: flex;
            justify-content: center;
            align-items: center;
            text-transform: uppercase;
            border-radius: 4px;
            border: 1px solid rgba(0,0,0,0.3);
            padding: 0 8px;
            margin: 0 0.5em 0 0;
            color: rgba(0,0,0,0.6);
        }
        .btn.selected {
            color: #fff;
            background-color: rgba(0,0,0,0.6);
        }
        .actions {
            button {
                margin: 4px 0 4px 8px !important;
            }
        }
    }
    .booking-dialog-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        h2 {
            margin: 0;
            text-align: right;
            padding: 0.25em;
        }
    }
    .form-container {
        .MuiDialogContent-root:first-child {
            padding-top: 0;
        }
        .MuiDialogContent-root {
            padding: 0.25em;
        }
        .MuiDialogActions-root {
            padding: 0.25em;
        }
    }
    .MuiFormControl-root {
        margin-right: 1em;
    }
    .booking-header-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0.5em 0;
        .MuiTextField-root {
            margin: 0 1em 0 0;
            width: 180px;
        }
        .react-datetime-picker {
            padding: 0.25em;
            margin-right: 1em;
        }
    }
}
.react-datetime-picker {
    padding: 1em 1em 1em 0;
    .react-datetime-picker__wrapper {
        padding: 13.5px 14px;
        border-radius: 4px;
        border-color: rgba(0, 0, 0, 0.25);
    }
}