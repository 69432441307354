.plusMinusField-container {
    display: inline-flex;
    justify-content: space-between;
    width: 100px;
    height: 25px;
    background-color: #0395fe;
    border-radius: 300px;
    margin: 5px 0;
}

.plusMinusField-count {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: white;
    user-select: none;
}

.plusMinusField-plus, .plusMinusField-minus {
    border: 0px solid;
    width: 25px;
    height: 25px;
    cursor: pointer;
    svg {
        .svgActiveBg {
            fill: #22508a;
        }
        .svgActiveBorder {
            fill: rgba(0,0,0,0)
        }
        .svgActiveText {
            fill: white;
        }
        .svgPassiveBorder {
            fill: rgba(0,0,0,0)
        }
        .svgPassiveBg {
            fill: grey;
        }
        .svgPassiveText {
            fill: rgba(255,255,255, 0.7);
        }
        width: 100%;
        height: 100%;
    }
}

.toBasketIcon {
    border-radius: 30px;
    background: #0395fe;
    color: white;
    display: inline-flex;
    width: 100px;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    height: 25px;
    cursor: pointer;
    margin: 5px 0;
    user-select: none;
}