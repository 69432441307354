.entity-availability-container {
    width: 100%;
    height: 100%;
    .data-filter-container {
        height: 48px;
        .react-datetime-picker__wrapper {
            margin-top: 4px;
            padding: 6px;
        }
    }
    .data-grid-container {
        height: calc(100% - 5em);
    }
    .react-datetime-picker {
        padding: 0 4px;
    }
}