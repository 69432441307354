.login-container {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    justify-content: flex-end;
    padding: 6px;
    width: 42px;
    .MuiButton-root {
        //margin-top: 2px;
        min-width: 30px !important;
        padding-right: 10px !important;
        height: 24px;
    }
    .MuiButton-startIcon {
        margin-right: 0 !important;
    }
}